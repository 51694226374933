import { lazy } from "react";
import locales from "./locales";
import routes from "./routes";
import themes from "./themes";
import parseLanguages from "base-shell/lib/utils/locale";
import grants from "./grants";
import Loading from "material-ui-shell/lib/components/Loading/Loading";
import {
  defaultUserData,
  isGranted,
  isAnyGranted,
} from "rmw-shell/lib/utils/auth";
import { getDefaultRoutes } from "./getDefaultRoutes";
import moment from "moment";
import { getProducts } from "utils/subscriptions";

const config = {
  firebase: {
    prod: {
      initConfig: {
        apiKey: "AIzaSyAcTLaSKc-YXw3CT9BOdKSOjB2KNRDwE4w",
        //authDomain: "gorilla-tool-e4e25.firebaseapp.com",
        authDomain: "app.gorilla-tool.com",
        databaseURL:
          "https://gorilla-tool-e4e25-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "gorilla-tool-e4e25",
        storageBucket: "gorilla-tool-e4e25.appspot.com",
        messagingSenderId: "42582745637",
        appId: "1:42582745637:web:03d8213ed16ed7e6b415cc",
        measurementId: "G-3NQ1VR9SL8",
      },
      messaging: {
        publicVapidKey:
          "BBa3KD5rggYxFMSOajrAj2Wm1nYbjJrNBRcpNgbMOfN3g0MsCxa3Re18QtahBJcbZyXW48b6zqA0DfejczZ-MXM",
      },
    },
    devp: {
      initConfig: {
        apiKey: "AIzaSyAcTLaSKc-YXw3CT9BOdKSOjB2KNRDwE4w",
        //authDomain: "gorilla-tool-e4e25.firebaseapp.com",
        authDomain: "app.gorilla-tool.com",
        databaseURL:
          "https://gorilla-tool-e4e25-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "gorilla-tool-e4e25",
        storageBucket: "gorilla-tool-e4e25.appspot.com",
        messagingSenderId: "42582745637",
        appId: "1:42582745637:web:03d8213ed16ed7e6b415cc",
        measurementId: "G-3NQ1VR9SL8",
      },
      messaging: {
        publicVapidKey:
          "BBa3KD5rggYxFMSOajrAj2Wm1nYbjJrNBRcpNgbMOfN3g0MsCxa3Re18QtahBJcbZyXW48b6zqA0DfejczZ-MXM",
      },
    },
    dev: {
      initConfig: {
        apiKey: "AIzaSyDlu2vI3rppBQrKLhM9vwuuDSHv72eFtwI",
        authDomain: "libakolator.firebaseapp.com",
        databaseURL:
          "https://libakolator-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "libakolator",
        storageBucket: "libakolator.appspot.com",
        messagingSenderId: "957580737578",
        appId: "1:957580737578:web:c6839d2f6ac6d844bdd753",
        measurementId: "G-3MGST6GFP3",
      },
      messaging: {
        publicVapidKey:
          "BJ88vKf2rumm_zuet-0tyreUIoPvjZ5N6b4ze4eiQnYitmbEwoUX8OjrXJ_XbQH_Vqe6ac0VDJ0q8vXldhIqBO4",
      },
    },

    firebaseuiProps: {
      signInOptions: ["google.com", "password"],
    },
  },
  googleMaps: {
    apiKey: "AIzaSyByMSTTLt1Mf_4K1J9necAbw2NPDu2WD7g",
  },
  auth: {
    grants,
    redirectTo: "/dashboard",
    persistKey: "base-shell:auth",
    signInURL: "/signin",
    onAuthStateChanged: async (user, auth) => {
      const { getDatabase, ref, onValue, get, update, off } = await import(
        "firebase/database"
      );
      const {
        getFirestore,
        collection,
        onSnapshot,
        getDocsFromServer,
        getDocFromServer,
      } = await import("firebase/firestore");
      const db = getDatabase();
      const fs = getFirestore();

      try {
        if (user != null) {
          const { metadata } = user;
          const { createdAt } = metadata || {};

          const grantsSnap = await get(ref(db, `user_grants/${user.uid}`));
          const notifcationsDisabledSnap = await get(
            ref(db, `disable_notifications/${user.uid}`)
          );

          const isAdminSnap = await get(ref(db, `admins/${user.uid}`));
          const isAdmin = !!isAdminSnap.val();

          const subscrptionsRef = collection(
            fs,
            `customers/${user.uid}/subscriptions`
          );
          const subscriptions = await getDocsFromServer(subscrptionsRef);
          const products = await getProducts(subscriptions, getDocFromServer);

          onValue(ref(db, `user_grants/${user.uid}`), (snap) => {
            auth.updateAuth({ grants: snap.val() });
          });
          onValue(ref(db, `disable_notifications/${user.uid}`), (snap) => {
            auth.updateAuth({ notificationsDisabled: !!snap.val() });
          });
          onValue(ref(db, `admins/${user.uid}`), (snap) => {
            auth.updateAuth({ isAdmin: !!snap.val() });
          });

          onSnapshot(
            subscrptionsRef,
            { includeMetadataChanges: true },
            async (snap) => {
              const p = await getProducts(snap, getDocFromServer);
              auth.updateAuth({ products: p });
            }
          );

          auth.updateAuth({
            ...defaultUserData(user),
            grants: grantsSnap.val(),
            notificationsDisabled: notifcationsDisabledSnap.val(),
            isAdmin: !!isAdminSnap.val(),
            isGranted,
            isAnyGranted,
            products,
            createdAt: moment(parseInt(createdAt), "x"),
          });

          update(ref(db, `users/${user.uid}`), {
            displayName: user.displayName,
            uid: user.uid,
            photoURL: user.photoURL,
            providers: user.providerData,
            emailVerified: user.emailVerified,
            isAnonymous: user.isAnonymous,
            notificationsDisabled: notifcationsDisabledSnap.val(),
          });

          if (isAdmin) {
            for (let i = 0; i < locales.length; i++) {
              const { locale = "en", messages = [] } = locales[i];
              const m = (await messages)["default"];
              const entries = Object.entries(m);

              for (let y = 0; y < entries.length; y++) {
                const [key, value] = entries[y];

                await update(ref(db, `/translations/${locale}/${key}`), {
                  defaultMessage: value,
                });
              }
            }
          }
        } else {
          off(ref(db));

          auth.setAuth(defaultUserData(user));
        }
      } catch (error) {
        console.warn(error);
      }
    },
  },
  getDefaultRoutes: getDefaultRoutes,
  routes,
  locale: {
    locales,
    persistKey: "base-shell:locale",
    defaultLocale: parseLanguages(["en", "de"], "en"),
    onError: async (e) => {
      try {
        const { id, defaultMessage } = e.descriptor;
        /*
        const { getDatabase, ref, update } = await import("firebase/database");
        const db = getDatabase();
       
        //console.warn(Object.keys(e.descriptor));
        for (let i = 0; i < locales.length; i++) {
          const { locale = "en" } = locales[i];
          try {
            
            await update(ref(db, `/translations/${locale}/${id}`), {
              defaultMessage: defaultMessage ? defaultMessage : id,
            });
            
          } catch (error) {
            console.log("error", error);
          }
        }
        */

        /*
        update(ref(db, `translations/${user.uid}`), {
          
        });
        */

        const missingTranslations = JSON.parse(
          localStorage.getItem("missing_translations")
        );

        localStorage.setItem(
          "missing_translations",
          JSON.stringify({ ...missingTranslations, [id]: defaultMessage })
        );
      } catch (error) {
        //console.log("e", e);
      }

      return;
    },
  },
  menu: {
    initialMenuOpen: false,
    initialMobileMenuOpen: false,
    MenuContent: lazy(() => import("../components/Menu/MenuContent")),

    MenuHeader: lazy(() =>
      import("material-ui-shell/lib/components/MenuHeader/MenuHeader")
    ),
    appBarLeadingContent: (
      <img
        src={"/menu-logo.png"}
        alt="logo"
        style={{
          height: 28,
          marginRight: 20,
          color: "white",
          justifySelf: "center",
        }}
      />
    ),
  },
  theme: {
    themes,
    defaultThemeID: "default",
    defaultType: "light",
  },
  pages: {
    //LandingPage: lazy(() => import("../pages/LandingPage")),
    PageNotFound: lazy(() => import("../pages/PageNotFound")),
  },
  components: {
    Menu: lazy(() => import("material-ui-shell/lib/containers/Menu/Menu")),
    Loading,
  },

  containers: {
    LayoutContainer: lazy(() => import("containers/LayoutContainer")),
  },
  amazon: {
    splits: [
      {
        label: "production_costs",
        rate: "productionRate",
        value: "productionCosts",
        color: "green",
      },

      {
        label: "preparation_costs",
        rate: "preparationRate",
        value: "preparationCosts",
        color: "#36A2EB",
      },

      {
        label: "shipping_costs",
        rate: "sendingRate",
        value: "sendingCosts",
        color: "#ffcc00",
      },
      {
        label: "storage_costs",
        rate: "stockRate",
        value: "stockCosts",
        color: "brown",
      },
      {
        label: "return_costs",
        rate: "returnRate",
        value: "returnCosts",
        color: "red",
      },
      {
        label: "disposal_costs",
        rate: "disposalRate",
        value: "disposalCosts",
        color: "purple",
      },
      {
        label: "selling_costs",
        rate: "sellingRate",
        value: "sellingCosts",
        color: "orange",
        isDynamic: true,
      },
    ],
    services: [
      "labeling",
      "bubble_wrap",
      "packaging_tape",
      "packaging_opaqued",
      "foil_pouch",
    ],
    shipping_programms: [
      "local",
      "pan_eu",
      "mid_eu",
      "efn",
      "small_light",
      "small_light_mid_eu",
    ],
    storage_times: ["js", "od"],
    sizes: {
      small_letter: {
        own_weight: 20,
        long: 20,
        middle: 15,
        short: 1,
        weight: 80,
      },
      default_letter: {
        own_weight: 40,
        long: 33,
        middle: 23,
        short: 2.5,
        weight: 460,
      },
      large_letter: {
        own_weight: 40,
        long: 33,
        middle: 23,
        short: 4,
        weight: 960,
      },
      extra_large_letter: {
        own_weight: 40,
        long: 33,
        middle: 23,
        short: 6,
        weight: 960,
      },
      small_package: {
        own_weight: 100,
        long: 35,
        middle: 25,
        short: 12,
        weight: 3900,
      },
      default_package: {
        own_weight: 100,
        long: 45,
        middle: 34,
        short: 26,
        weight: 11900,
      },
      small_oversize: {
        own_weight: 240,
        long: 61,
        middle: 46,
        short: 46,
        weight: 14760,
        isOversize: true,
      },
      default_oversize: {
        own_weight: 240,
        long: 120,
        middle: 60,
        short: 30,
        weight: 29760,
        isOversize: true,
      },
      large_oversize: {
        own_weight: 240,
        long: 150,
        middle: 60,
        short: 30,
        weight: 31500,
        isOversize: true,
      },
      oversize: {
        own_weight: 240,
        long: 999,
        middle: 999,
        short: 999,
        weight: 99999,
        isOversize: true,
      },
    },
  },
};

export default config;
