import red from "@mui/material/colors/red";

const themes = [
  {
    id: "default",
    color: red[500],
    source: {
      /*
      typography: {
        fontFamily: "Nexa",
      },
      */
      palette: {
        primary: {
          main: "#eb2f26",
        },
        light: "#ff7961",
        secondary: {
          main: "#505456",
        },
        error: red,
      },
    },
  },
];

export default themes;
