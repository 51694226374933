const getProducts = async (subscriptionsSnap, getDocFromServer) => {
  let raw = [];

  let products = [];

  try {
    subscriptionsSnap.forEach((s) => {
      raw.push(s);
    });

    //console.log("raws", raw.length);

    for (let i = 0; i < raw.length; i++) {
      const s = raw[i];
      const { product, status } = s.data();
      //console.log("sub", s.data());
      //console.log("status", i, status);
      //raw.push(s);
      let p = await getDocFromServer(product);
      //console.log("p1x", p.data());
      if (status === "active") {
        products.push(p.id);
      }
    }
  } catch (error) {
    console.log("error", error);
  }

  //console.log("products func", products);

  return products;
};

export { getProducts };
