/* eslint-disable react/jsx-key */
import AuthorizedRoute from "base-shell/lib/components/AuthorizedRoute";
import React, { lazy } from "react";

const getRoutes = (plural = "", singular = "", List, Single) => {
  return [
    {
      path: `/${plural}`,
      exact: true,
      element: (
        <AuthorizedRoute>
          <List />
        </AuthorizedRoute>
      ),
    },
    {
      path: `/${plural}/:uid`,
      exact: true,
      element: (
        <AuthorizedRoute>
          <Single />
        </AuthorizedRoute>
      ),
    },
    {
      path: `/create_${singular}`,
      exact: true,
      element: (
        <AuthorizedRoute>
          <Single />
        </AuthorizedRoute>
      ),
    },
  ];
};

const Translations = lazy(() => import("../pages/Translations"));
const CreateFeedback = lazy(() => import("../pages/Feedback"));
const FeedbackEmails = lazy(() => import("../pages/FeedbackEmails"));
const Feedback = lazy(() => import("../pages/Feedbacks/Single"));
const Currencies = lazy(() => import("../pages/Currencies"));
const Currency = lazy(() => import("../pages/Currencies/Single"));
const Templates = lazy(() => import("../pages/Templates"));
const Template = lazy(() => import("../pages/Templates/Single"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Calculations = lazy(() => import("../pages/Calculations"));
const Calculation = lazy(() => import("../pages/Calculations/Single"));
const Categories = lazy(() => import("../pages/Categories"));
const Category = lazy(() => import("../pages/Categories/Single"));
const Markets = lazy(() => import("../pages/Markets"));
const Market = lazy(() => import("../pages/Markets/Single"));
const Version = lazy(() => import("../pages/Versions/Single"));
const Report = lazy(() => import("../pages/Versions/Report"));
const Feedbacks = lazy(() => import("../pages/Feedbacks"));
const Trackings = lazy(() => import("../pages/Trackings"));
const Tracking = lazy(() => import("../pages/Trackings/Single"));

const routes = [
  {
    path: "/",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Dashboard />
      </AuthorizedRoute>
    ),
  },
  {
    path: "/dashboard",
    exact: true,
    element: (
      <AuthorizedRoute>
        <Dashboard />
      </AuthorizedRoute>
    ),
  },

  ...getRoutes("currencies", "currency", Currencies, Currency),
  ...getRoutes("calculations", "calculation", Calculations, Calculation),
  ...getRoutes("templates", "template", Templates, Template),
  ...getRoutes("categories", "category", Categories, Category),
  ...getRoutes("markets", "market", Markets, Market),
  ...getRoutes("trackings", "tracking", Trackings, Tracking),
  {
    path: `/translations`,
    exact: true,
    element: (
      <AuthorizedRoute>
        <Translations />
      </AuthorizedRoute>
    ),
  },
  {
    path: `/send_feedback`,
    exact: true,
    element: (
      <AuthorizedRoute>
        <CreateFeedback />
      </AuthorizedRoute>
    ),
  },
  {
    path: `/calculations/:calculationUid/versions/:uid`,
    exact: true,
    element: (
      <AuthorizedRoute>
        <Version />
      </AuthorizedRoute>
    ),
  },
  {
    path: `/calculations/:calculationUid/versions/:uid/report`,
    exact: true,
    element: (
      <AuthorizedRoute>
        <Report />
      </AuthorizedRoute>
    ),
  },

  {
    path: `/feedbacks`,
    exact: true,
    element: (
      <AuthorizedRoute>
        <Feedbacks />
      </AuthorizedRoute>
    ),
  },
  {
    path: `/feedbacks/:uid`,
    exact: true,
    element: (
      <AuthorizedRoute>
        <Feedback />
      </AuthorizedRoute>
    ),
  },
  {
    path: `/feedback_emails`,
    exact: true,
    element: (
      <AuthorizedRoute>
        <FeedbackEmails />
      </AuthorizedRoute>
    ),
  },
];

export default routes;
