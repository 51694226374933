export const grants = [
  "default",
  "create_item",
  "edit_item",
  "delete_item",
  "read_items",
  "create_countries",
  "edit_country",
  "read_country",
  "delete_country",
  "create_currencies",
  "edit_currency",
  "read_currency",
  "delete_currency",
  "create_suppliers",
  "edit_supplier",
  "read_supplier",
  "delete_supplier",
  "create_markets",
  "edit_market",
  "read_market",
  "delete_market",
  "create_shippings",
  "edit_shipping",
  "read_shipping",
  "delete_shipping",
  "create_stock_costs",
  "edit_stock_cost",
  "read_stock_cost",
  "delete_stock_cost",
];

export default grants;
