const locales = [
  {
    locale: "en",
    messages: import("./en"),
  },

  {
    locale: "de",
    messages: import("./de"),
  },
];

export default locales;
